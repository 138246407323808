<template>
  <div class="welcome h100">
    <p v-if="!measure">
      {{ $t('awesome') }}! <br>{{ $tc("noSessionMessage", 1) }}
    </p>
    <h1 v-if="measure">
      {{ $t('welcomeToOwl') }}
    </h1>
    <p v-if="measure">
      {{ welcomeBody }}
    </p>
    <router-link
      :to="{ name: 'Session' }"
      class="btn btn-primary welcome-continue"
    >
      {{ $t("next") }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  data: () => {
    return {
      measure: true,
      showModal: false,
      welcomeBody: ''
    }
  },
  created () {
    const user = this.$store.getters.loggedInUser
    const settings = this.$store.getters.generalSettings
    const clinicName = 'Owl'
    const customerName = settings.customer_name
    let message = 'welcomeIntroMessagePatient'

    if (!user.isPatient) {
      switch (user.relationToPatient) {
        case '3': // Mother
        case '4': // Father
        case '6': // Stepfather
        case '7': // Stepmother
        case '30': // Foster Mother
        case '31': // Foster Father
        case '32': // Mother1
        case '33': // Mother2
        case '34': // Father1
        case '35': // Father2
        case '50': // Step Parent
        case '57': // Parent
          message = 'welcomeIntroMessageParents'
          break
        case '26': // Teacher
          message = 'welcomeIntroMessageTeacher'
          break
        default: // All Others
          message = 'welcomeIntroMessageOthers'
          break
      }
    }

    this.welcomeBody = this.$t(message, { clinicName: clinicName, customerName: customerName })
  }
}
</script>
